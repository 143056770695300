<template>
  <div class="profile">
    <h2>Профиль</h2>
    <div class="profile-content">
      <div class="info">
        <h4>Личные данные</h4>
        <div class="info-data">
          <div class="field">
            <label>Телефон</label>
            <p>{{ profile.phone }}</p>
          </div>
          <div class="field">
            <label>Email</label>
            <p>
              {{
                profile.email && profile.email.length
                  ? profile.email
                  : '—'
              }}
            </p>
          </div>
        </div>
        <div class="info-data">
          <div class="field">
            <label>Фамилия</label>
            <p>{{ profile.lastname }}</p>
          </div>
          <div class="field">
            <label>Имя</label>
            <p>{{ profile.firstname }}</p>
          </div>
          <div class="field">
            <label>Отчество</label>
            <p>{{ profile.patronymic }}</p>
          </div>
        </div>
        <div class="info-data">
          <div class="field">
            <label>Дата рождения</label>
            <p>{{ profile.birthday }}</p>
          </div>
        </div>
      </div>
      <hr>
      <div class="info">
        <h4>Паспортные данные</h4>
        <div class="info-data">
          <div class="field">
            <label>Серия</label>
            <p>{{ profile.passport_serie }}</p>
          </div>
          <div class="field">
            <label>Номер</label>
            <p>{{ profile.passport_number }}</p>
          </div>
          <div class="field">
            <label>Код подразделения</label>
            <p>{{ profile.passport_issuecode }}</p>
          </div>
        </div>
        <div class="info-data">
          <div class="field">
            <label>Адрес регистрации</label>
            <p>{{ profile.address }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "Profile",
  computed: {
    ...mapGetters(["profile"]),
  },
  mounted() {
    this.user().catch(() => {
      this.$router.push({ name: "login" });
    });
  },
  methods: {
    ...mapActions(["user"]),
  },
};
</script>

<style lang="scss">
.profile {
  margin-top: 1.5rem;

  h2 {
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    color: #000000;
    margin: 0 0 40px;

    @media screen and (max-width: 640px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }

  .profile-content {
    hr {
      margin-top: 40px;
      margin-bottom: 40px;
      border: 1px solid transparentize(#000000, 0.9);
    }

    .info {
      h4 {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: #000000;
        margin: 0 0 24px;

        @media screen and (max-width: 640px) {
          font-size: 18px;
          line-height: 32px;
          margin-bottom: 16px;
        }
      }

      .info-data {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .field {
          margin-right: 40px;
          margin-bottom: 24px;

          label {
            font-size: 14px;
            line-height: 24px;
            font-family: "Roboto", sans-serif;
            color: #000000;
            display: inline-block;
            font-weight: bold;
          }

          p {
            font-size: 18px;
            font-family: "Roboto", sans-serif;
            color: #000000;
            line-height: 24px;
            margin: 0;
          }
        }

        @media screen and (max-width: 640px) {
          flex-direction: column;

          .field {
            margin-right: 0;
            margin-bottom: 10px;
            display: flex;

            label {
              font-size: 14px;
              line-height: 24px;
              max-width: 140px;
              min-width: 140px;
              width: 100%;
              margin-right: 20px;
            }

            p {
              font-size: 16px;
              line-height: 24px;
              display: inline-block;
            }
          }
        }

        @media screen and (max-width: 370px) {
          .field {
            flex-direction: column;

            label {
              max-width: 100%;
            }

            p {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
