<template>
  <div>
    <div 
      v-for="(item, i) in displayedList" 
      :key="i" 
      :class="classItem"
    >
      <slot
        name="item"
        :item="item"
      >
        {{ item }}
      </slot>
    </div>

    <div
      v-if="pages.length > 1"
      class="pagination"
    >
      <div
        v-if="pageActive !== 1"
        class="pagination__item _prev"
        @click="pagePrev"
      >
        <Icon type="arrow-right" />
      </div>
      <div
        v-for="pageNumber in displayedPages"
        :key="pageNumber"
        :class="['pagination__item', { active: pageNumber === pageActive }]"
        @click="pageActive = pageNumber"
      >
        {{ pageNumber }}
      </div>
      <div 
        v-if="pageActive < pages.length"
        class="pagination__item"
        @click="pageNext"
      >
        <Icon type="arrow-right" />
      </div>
    </div>
  </div>
</template>

<style lang="css" src="./pagination.css"></style>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
      required: true,
    },
    classItem: {
      type: String,
      default: null,
    },
    pageStart: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      pageActive: this.pageStart,
    };
  },
  computed: {
    pages() {
      let numberOfPages = Math.ceil(this.list.length / this.perPage);
      return Array.from({ length: numberOfPages }, (e, i) => i + 1);
    },
    displayedList() {
      return this.paginate(this.list);
    },
    displayedPages() {
      let pagesList = [];
      let from = this.pageActive - 1;
      let to = this.pageActive + 4;
      
      if (this.pages.length <= 5) {
        pagesList = this.pages;
      }
      
      if (this.pages.length - this.pageActive < 5) {
        from = this.pages.length - 5;
        to = this.pages.length;
      }

      if (this.pages.length > 5) {
        pagesList = this.pages.slice(from, to);
      }

      return pagesList;
    },
  },
  methods: {
    pageNext() {
      this.pageActive++;
    },
    pagePrev() {
      this.pageActive--;
    },
    paginate() {
      let from = this.pageActive * this.perPage - this.perPage;
      let to = this.pageActive * this.perPage;
      return this.list.slice(from, to);
    },
  },
};
</script>
